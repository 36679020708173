type Props = {
  children: React.ReactNode;
  className?: string;
};

const SolidHeader = ({ children, className = "" }: Props) => {
  return <div className={`header ${className}`}>{children}</div>;
};

export default SolidHeader;
