"use client";
import SolidHeader from "@/components/landingPage/header/SolidHeader";
import {
  ButtonSize,
  ButtonType,
  ButtonVariation,
  ButtonVisual,
} from "@/types/button";
import { IconList } from "@/types/icons";
import { ErrorPageEvent } from "@/utils/events";
import { useEffect } from "react";
import Button from "../Button";

type Props = {
  style: string;
  code: string;
  referer?: string | null;
  error?: any;
};

const HeaderError = ({ style, code, referer, error }: Props) => {
  const sendErrorToStapi = () => {
    // console.log("code");
    ErrorPageEvent(code, referer ? `${referer} ${error}` : "");
  };

  useEffect(() => {
    sendErrorToStapi();
  }, []);

  return (
    <SolidHeader className={`header-gradient-${style} pt-28 md:pt-40`}>
      <div className="flex flex-col items-center justify-center -space-y-8">
        <p
          className={`text-[160px] font-bold text-${style}-500 pr-4 leading-[160px] -tracking-[15px]`}
        >
          {code}
        </p>
        <h3 className=" font-bold text-white">Nicht gefunden</h3>
      </div>
      <div className="flex flex-col items-center justify-center space-y-4">
        <p className="text-lg">
          Das tut uns leid. Diese Seite konnten wir leider nicht finden.
          <br />
          Kehren Sie einfach zu unserer Startseite zurück.
        </p>
        <Button
          visual={
            style === "primary" ? ButtonVisual.PRIMARY : ButtonVisual.SECONDARY
          }
          variation={ButtonVariation.SOLID_WHITE}
          size={ButtonSize.LARGE}
          className="w-full md:w-60"
          label="Startseite"
          icon={IconList.ARROW_RIGHT}
          iconPosition={"right"}
          link="/"
          type={ButtonType.INTERNAL}
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-4">
        <p className="text-lg">
          Haben Sie eine Frage an uns?
          <br />
          Rufen Sie uns einfach an. Wir sind 24h für Sie da.
        </p>
        <Button
          visual={
            style === "primary" ? ButtonVisual.PRIMARY : ButtonVisual.SECONDARY
          }
          variation={ButtonVariation.WHITE_OUTLINE}
          size={ButtonSize.LARGE}
          icon={IconList.PHONE}
          iconPosition={"left"}
          label="
          0800 88 44 04"
          className="w-full md:w-60"
          link="/"
          type={ButtonType.PHONE}
        />
      </div>
    </SolidHeader>
  );
};

export default HeaderError;
