import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CImage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/HeaderError.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/landingPage/customers/CustomersListMobile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/Footer/FooterDesktopList.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/DesktopMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/DesktopNavBarPhone.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/LoginButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/MobileMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/MobileUserMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/UserDropDownMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/PhoneBar/PhoneBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/dropdown-menu.tsx")